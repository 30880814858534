module.exports = {
  locales: ['en', 'pt', 'es'],
  defaultLocale: 'en',
  localeDetection: false,
  pages: {
    '*': ['common'],
  },
  loadLocaleFrom: (lang, namespace) => {
    return import(`./src/locales/${lang}/${namespace}.json`).then(module => module.default)
  },
  // 如果是根据域名区分，打开注释并编辑
  // domains: [
  //   {
  //     domain: 'www.zh.com',
  //     defaultLocale: 'zh',
  //   },
  //   {
  //     domain: 'www.en.com',
  //     defaultLocale: 'en',
  //   },
  // ],
}
