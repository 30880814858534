module.exports = {
  s3: {
    // if needed
  },
  cloundFront: {
    cdn_path: 'cdn_path', // CNAMES
    distribution_id: 'distribution_id',
  },
  sensor: {
    server_url: 'https://sensors.snaptube.app/sa?project=Snappea',
  },
  // firebase 官网创建项目后一键生成
  firebase: {
    apiKey: 'AIzaSyC8Y9pPalv-F9UKeEOnSfdrWpD4eW8McDQ',
    authDomain: 'fe-converter.firebaseapp.com',
    projectId: 'fe-converter',
    storageBucket: 'fe-converter.appspot.com',
    messagingSenderId: '1023892692132',
    appId: '1:1023892692132:web:a69458a7010c8c94c8da31',
    measurementId: 'G-B65QKZ1E07',
  },
  sentry: {
    // sentry 工程设置中获取
    // dsn: 'dsn_url',
  },
}
