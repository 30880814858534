
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import React, { useEffect } from "react"
import { AppProps } from "next/app"
import Head from "next/head"
import sa from "@/utils/sa"
import configs from "@/configs"
import { useRouter } from "next/router"

import "antd-mobile/es/popover/style/index.css"
import "antd-mobile/es/list/style/index.css"
import "antd-mobile/es/picker/style/index.css"
import "antd-mobile/es/picker-view/style/index.css"
import "../styles/reset.css"
import "../styles/index.css"
import "../styles/page.css"

const ALL_LOCALES = ["en", "pt", "es"]

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter()
  const { locale, pathname, asPath } = router

  useEffect(() => {
    sa.init({
      server_url: configs.sensor.server_url,
      is_track_single_page: true, // 单页面配置，默认开启，若页面中有锚点设计，需要将该配置删除，否则触发锚点会多触发 $pageview 事件
      use_client_time: true,
      show_log: true,
      send_type: "beacon",
      heatmap: {
        //是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 "not_collect" 表示关闭。
        clickmap: "default",
        //是否开启触达注意力图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 "default" 表示开启。
        scroll_notice_map: "not_collect",
      },
    })
    // 注册公共属性
    sa.registerPage({
      index_plan: window.MP3_AB_INDEX_PLAN || "",
      web_host: window.location.host,
      web_url: window.location.href,
      path: window.location.pathname,
    })
    sa.quick("autoTrack") //用于采集 $pageview 事件
    sa.use("PageLoad")
    sa.use("PageLeave")
  }, [])

  const commonLink =
    pathname === "/" && locale === "en"
      ? "https://www.online-audioconvert.com/"
      : pathname === "/" && locale !== "en"
      ? "https://www.online-audioconvert.com/" + locale + "/"
      : pathname !== "/" && locale === "en"
      ? "https://www.online-audioconvert.com" + asPath
      : "https://www.online-audioconvert.com/" + locale + asPath

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
        <meta name="google-site-verification" content="bB0UJQDGHQ2eSZgZM-H-SQShVKSneHqMaSi1CH0g3bw" />
        <link rel="icon" type="image/x-icon" href="/convert-logo.png" />
        <link rel="canonical" href={commonLink} />
        {ALL_LOCALES.map(locale => (
          <link
            key={locale}
            rel="alternate"
            href={
              pathname === "/" && locale === "en"
                ? "https://www.online-audioconvert.com/"
                : pathname === "/" && locale !== "en"
                ? "https://www.online-audioconvert.com/" + locale + "/"
                : pathname !== "/" && locale === "en"
                ? "https://www.online-audioconvert.com" + asPath
                : "https://www.online-audioconvert.com/" + locale + asPath
            }
            hrefLang={locale}
          />
        ))}
        <link
          rel="alternate"
          href={
            pathname === "/" ? "https://www.online-audioconvert.com/" : "https://www.online-audioconvert.com" + asPath
          }
          hrefLang="x-default"
        />
        <link rel="apple-touch-icon" href="/convert-logo.png" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link rel="preload stylesheet" href="https://static.snaptube.in/iconfont/iconfont.css" as="style" />
        <script
          data-rh="true"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `{"@context":"https://schema.org","@type":"SoftwareApplication","name":"Online AudioConvert","image":"https://www.online-audioconvert.com/convert-logo.png","offers":{"@type":"Offer","price":"0","priceCurrency":"USD"},"screenshot":"https://www.online-audioconvert.com/convert-logo.png","aggregateRating":{"@type":"AggregateRating","ratingValue":"4.8","ratingCount":"226897"}}`,
          }}
        />

        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
          as="style"
          crossOrigin="anonymous"
        ></link>
      </Head>
      <Component {...pageProps} />
    </>
  )
}

const __Page_Next_Translate__ = MyApp


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      
// @ts-ignore
    });
// @ts-ignore
  